// ----------------------------------------------------------------------------------------------
// NOTE Browser style : Reset --------------------------------------------------------- Overwrite
// ----------------------------------------------------------------------------------------------

// ----------------------------------------------------------------------------------------------
// NOTE Project ---------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------

// NOTE Link

// NOTE Scrollbar

// NOTE Sortable table --------------------------------------------------------------------------

// NOTE Icons -----------------------------------------------------------------------------------

// ----------------------------------------------------------------------------------------------
// NOTE Bootstrap --------------------------------------------------------------------- Overwrite
// ----------------------------------------------------------------------------------------------

// NOTE Pagination ------------------------------------------------------------------------------

// NOTE Button ----------------------------------------------------------------------------------

// NOTE Modal -----------------------------------------------------------------------------------

// NOTE Toast -----------------------------------------------------------------------------------

.toast-header {
  background-color: transparent;
}
.toast-body {
  background-color: rgba(255, 255, 255, 0.85);
}

// NOTE Nav -------------------------------------------------------------------------------------

// NOTE Table -----------------------------------------------------------------------------------

// NOTE Select ----------------------------------------------------------------------------------

// NOTE Form ------------------------------------------------------------------------------------

// Checkbox

// Radio

// NOTE Sortable table --------------------------------------------------------------------------
