// ----------------------------------------------------------------------------------------------
// SECTION Variables ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------

// ----------------------------------------------------------------------------------------------
// NOTE Project ---------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------

// NOTE Colors ----------------------------------------------------------------------------------

// ----------------------------------------------------------------------------------------------
// NOTE Bootstrap -------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------

// NOTE Utilities -------------------------------------------------------------------------------

$utilities: (
  "cursor": (
    property: cursor,
    class: cursor,
    responsive: false,
    values: pointer,
  )
);

// NOTE Color system ----------------------------------------------------------------------------

$primary    : #375F9B;
$secondary  : #8492A6;
$info       : #3C78DA;
$text-muted : #ACA7AA;

// NOTE Body ------------------------------------------------------------------------------------

// NOTE Grid ------------------------------------------------------------------------------------

// NOTE Borders ---------------------------------------------------------------------------------

// NOTE Typography  -----------------------------------------------------------------------------

$font-family-sans-serif: "Roboto", sans-serif;

@use "@fontsource/roboto/scss/mixins" as Roboto;
@include Roboto.fontFace(
  $weight  : 400,
  $display : fallback,
  $fontDir : "~@fontsource/roboto/files"
);

// NOTE Links -----------------------------------------------------------------------------------

$link-decoration: none;

// NOTE Badges ----------------------------------------------------------------------------------
// NOTE Buttons ---------------------------------------------------------------------------------

// NOTE Navbar ----------------------------------------------------------------------------------
// NOTE Navs ------------------------------------------------------------------------------------

// NOTE Modal -----------------------------------------------------------------------------------

// NOTE Dropdowns -------------------------------------------------------------------------------
// NOTE Cards -----------------------------------------------------------------------------------

// NOTE List group ------------------------------------------------------------------------------
// NOTE Pagination ------------------------------------------------------------------------------
// NOTE Popovers --------------------------------------------------------------------------------
// NOTE Typography ------------------------------------------------------------------------------
// NOTE Forms -----------------------------------------------------------------------------------

// NOTE Tables ----------------------------------------------------------------------------------

// NOTE Z-index ---------------------------------------------------------------------------------

// !SECTION Variables
