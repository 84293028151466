.app-loading {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  .spinner {
    height: 200px;
    width: 200px;
    animation: rotate 2s linear infinite;
    transform-origin: center center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    .path {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      animation: dash 1.5s ease-in-out infinite;
      stroke-linecap: round;
      stroke: #ddd;
    }
  }
  #logo {
    width: 100px;
    height: 100px;
    background: url('../img/air-liquide-altecref.svg') center center no-repeat;
    background-size: 100% 100%;
  }
  #powered-by {
    display: block;
    width: 59px;
    height: 5px;
    background: url('../img/powered-by.svg') center center no-repeat;
    margin-top: 10px;
    margin-bottom: 2px;
    background-size: 100% 100%;
  }
  #caliatys {
    display: block;
    width: 59px;
    height: 16px;
    background: url('../img/caliatys-logo.svg') center center no-repeat;
    background-size: 100% 100%;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
